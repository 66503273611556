import React, {Component} from 'react';
import {Navigate} from 'react-router-dom';
import { unmountComponentAtNode } from "react-dom";
import {appDatabasePrimaryFunctions, base} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Confetti from 'react-confetti';
import { getDistance } from 'geolib';
import Loading from '../utils/Loading';
import appBackgroundImage from '../../styles/images/background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import sampleCardImage from '../../styles/images/card_image.svg'
import '../../styles/css/Scratcher.css';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CustomCSS.css';
import {validateEmail, getObjectFromArray} from '../utils/HelpfulFunction';
import { Textfit } from 'react-textfit';
import ScratchCard from '../utils/ScratchCardNew'

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userEmail: props.appStorage.getItem('userEmail') || props.appStorage.getItem('tempToken') || false,
          active: false,
          width: 0,
          height: 0,
          currentGameId: null,
          startConfetti: false,
          bingoSquares: [],
          modal: false,
          locationChecking: true,
          loading: true
        };
        this.logout = this.logout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentDidMount(){
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.removeReactAlert();
            this.setState({
              currentGameId:key,
              startConfetti: false
            })
          }
        }
      });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });

      this.activeRef = base.listenTo('currentGame/active', {
        context: this,
        then(dataActive){
          if(this.props.variables && this.props.variables.collectDistance && dataActive){
            this.checkUsersLocation();
          } else {
            this.setState({
              active: dataActive,
              locationChecking: false,
              loading:false
            })
            if(dataActive){
              this.getSquares();
            }
          }
        }
      })

      this.bingoSquaresRef = base.listenTo('currentGame/bingoSquares', {
        context: this,
        then(data){
          this.setState({
            gameBingoSquaresArray: data,
            bingoSquaresObject: getObjectFromArray(data, "id", "key")
          })
        }
      })

      this.bingoSquaresRef = base.listenTo('currentGame/defaultCenterSquare', {
        context: this,
        then(data){
          this.setState({defaultCenterSquare: data})
        }
      })

      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(!validateEmail(userEmail)){
        base64EncodedEmail = userEmail;
      }

      this.didWinRef = base.listenTo('userAnswers/' + base64EncodedEmail + "/won", {
        context: this,
        then(data){
          if(data === true){
            this.fireWinningMessage(true);
          } else if(typeof data === 'string') {
            this.fireWinningMessage(false);
          }
        }
      })

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0);
      this.props.loadPage();
    }

    determineMaxNumberOfSquaresCompletedAllowed(boardSize){
      const integerBoardSize = parseInt(boardSize);
      switch (integerBoardSize) {
        case 1:
          return 0
        case 4:
          return 1
        case 9:
          return 6
        default:
          const minimumForBingo = Math.sqrt(integerBoardSize);
          return integerBoardSize - Math.floor(minimumForBingo);
      }
    }

    shuffleArray(array) {
      const defaultCenterIndex = array.findIndex(square => square.defaultCenter === true);
      // Remove the defaultCenter square from the array temporarily
      const defaultCenterSquare = array.splice(defaultCenterIndex, 1)[0];

      // Shuffle the rest of the array
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }

      // Insert the defaultCenter square in the middle
      const middleIndex = Math.floor(array.length / 2);
      array.splice(middleIndex, 0, defaultCenterSquare);
    }

    shuffle(array, numberOfCompletedSquaresAllowed) {
        // Shuffling the array
        this.shuffleArray(array);

        // Extract the defaultCenter square and remove it temporarily from the array
        const defaultCenterIndex = array.findIndex(square => square.defaultCenter === true);
        const defaultCenterSquare = array.splice(defaultCenterIndex, 1)[0];

        let completedSquares = array.filter(square => square.completed === true);
        let uncompletedSquares = array.filter(square => !square.completed);

        if (completedSquares.length >= numberOfCompletedSquaresAllowed) {
          // Sort to remove the non-defaultCenter squares first if needed
          completedSquares.sort((a, b) => a.defaultCenter ? -1 : (b.defaultCenter ? 1 : 0));

          completedSquares = completedSquares.slice(0, numberOfCompletedSquaresAllowed - 1);
        }

        let result = [...completedSquares, defaultCenterSquare, ...uncompletedSquares];

        // Shuffling the resulting array again to maintain randomness while ensuring the defaultCenter square is in the middle
        this.shuffleArray(result);

        return result;
    }

    async getSquares(userId, activateLoading){
      if(activateLoading){
        this.setState({loading: true})
      }
      if(!userId){
        const userEmail = this.state.userEmail;
        userId = btoa(userEmail);
        if(!validateEmail(userEmail)){
          userId = userEmail;
        }
      }
      const userAnswers = await base.fetch(`userAnswers/${userId}`, {context:this,asArray:true});
      let bingoSquares;
      if(userAnswers && userAnswers.length > 0){
        let cleanBingoSquares = [];
        for(const z in userAnswers){
          if(userAnswers[z] && userAnswers[z].id){
            cleanBingoSquares.push(userAnswers[z])
          }
        }
        if(cleanBingoSquares.length > 0){
          bingoSquares = cleanBingoSquares
        }
      } else {
        let bingoCardSize = await appDatabasePrimaryFunctions.ref('currentGame/bingoCardSize').once('value');
        bingoSquares = this.state.gameBingoSquaresArray || [];
        bingoCardSize = bingoCardSize.val() || 9;
        const maxNumberOfSquaresCompletedAllowed = this.determineMaxNumberOfSquaresCompletedAllowed(bingoCardSize);
        const squaresToChooseFrom = this.shuffle(bingoSquares, maxNumberOfSquaresCompletedAllowed, bingoCardSize);
        bingoSquares = squaresToChooseFrom.slice(0, bingoCardSize);
        const toSaveObject = {};
        let correctSquaresCount = 0;
        let finalSquaresToGiveUsers = [];
        let completedSquaresThatUserHasArray = [];
        for(const i in bingoSquares){
          const square = bingoSquares[i];
          if(square.completed){
            correctSquaresCount++;
            if(correctSquaresCount > maxNumberOfSquaresCompletedAllowed){
              continue;
            }
            completedSquaresThatUserHasArray.push(square)
          }
          finalSquaresToGiveUsers.push(square);
        }
        let hasBingo;
        let arrayOfSquareIdWinners = this.getCompletedSquares(completedSquaresThatUserHasArray);
        const tenantVariables = this.props.variables || {};
        if(finalSquaresToGiveUsers.length === bingoCardSize){
          do {
            this.shuffleArray(finalSquaresToGiveUsers);
            hasBingo = this.calculateIfBingo(finalSquaresToGiveUsers, bingoCardSize, arrayOfSquareIdWinners);
          } while (hasBingo);
          for(const i in finalSquaresToGiveUsers){
            const square = finalSquaresToGiveUsers[i];
            let id = square.id || square.key;
            toSaveObject[id] = {};
            toSaveObject[id]['id'] = id;
            toSaveObject[id]['location'] = i;
          }
        } else {
          confirmAlert({
            title: tenantVariables.toLateToGetBoardHeader || "Oh No!",
            variables: tenantVariables,
            message: tenantVariables.toLateToGetBoardBody || "All boards have been given out, come back next time!",
            buttons: []
          });
          return;
        }
        toSaveObject['uid'] = userId;
        toSaveObject['timeStamp'] = new Date().getTime();
        bingoSquares = toSaveObject;
        try {
          await appDatabasePrimaryFunctions.ref(`userAnswers/${userId}`).set(toSaveObject);
        } catch (e) {
          console.log(e);
          confirmAlert({
            title: tenantVariables.toLateToGetBoardHeader || "Oh No!",
            variables: tenantVariables,
            message: tenantVariables.toLateToGetBoardBody || "All boards have been given out, come back next time!",
            buttons: []
          });
          return;
        }
      }
      if(!Array.isArray(bingoSquares)){
        const newArrayToCreate = [];
        for(const i in bingoSquares){
          if(bingoSquares[i].id){
            newArrayToCreate.push(bingoSquares[i]);
          }
        }
        bingoSquares = newArrayToCreate
      }
      this.setState({
        bingoSquares: bingoSquares,
        arrayOfScratchedSquares: []
      })
      if(activateLoading){
        this.setState({loading: false})
      }
    }

    componentWillUnmount() {
      base.removeBinding(this.currentGameKeyRef);
      base.removeBinding(this.tenantRulesRef);
      base.removeBinding(this.activeRef);
      base.removeBinding(this.bingoSquaresRef);
      base.removeBinding(this.didWinRef);
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    changeMilesToMeters(milesToConvert){
      return milesToConvert*1609.344;
    }

    getLocation(){
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject("Geolocation is not supported by your browser. Please change browsers to play!");
        } else {
          const toCheckLatitude = this.props.variables.latitude || 51.525;
          const toCheckLongitude = this.props.variables.longitude || 7.4575;
          navigator.geolocation.getCurrentPosition(
              function(position) {
                resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                  latitude: toCheckLatitude,
                  longitude: toCheckLongitude,
                }))
              },
              (err) => {
                if(err.message === "User denied Geolocation"){
                  reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                } else {
                  console.log(err.message);
                  reject("An unknown error occurred, check your internet connection and try again");
                }
              }
          );
        }
      })
    }

    getLocationPermission(){
      const locationErrorTitle = "Location Error";
      this.getLocation().then(distance_meters => {
        this.props.appStorage.setItem('locationPermissions', "true");
        const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
        if(distance_meters <= allowed_distance){
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false,
            active: true
          });
          this.getSquares();
        } else if(this.props.variables.canPlay) {
          this.setState({
            modal:false,
            loading:false,
            locationChecking: false,
            active: true,
            locationCheckFailed: true
          });
          this.getSquares();
        } else {
          this.setState({
            modal:false,
            loading:false
          });
          confirmAlert({
            title: locationErrorTitle,
            variables: this.props.variables,
            message: "Too far from game area to participate!",
            buttons: [
              {
                label: 'Retry',
                onClick: () => {
                  this.checkUsersLocation()
                }
              }
            ]
          });
        }
      }, error => {
        console.log(error);
        this.setState({
          modal:false,
          loading:false
        });
        this.props.appStorage.setItem('locationPermissions', "false");
        if(typeof error != "string"){
          error = error.message
        }
        confirmAlert({
          title: locationErrorTitle,
          variables: this.props.variables,
          message: error,
          buttons: [
            {
              label: 'Retry',
              onClick: () => {
                this.checkUsersLocation()
              }
            }
          ]
        });
      })
    }

    checkUsersLocation(){
      const variables = this.props.variables;
      const locationPermissions = this.props.appStorage.getItem('locationPermissions');
      if(!this.props.variables.collectDistance) {
        this.setState({locationChecking: false})
      } else if(locationPermissions === "false" || !locationPermissions){
        const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
        const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
        confirmAlert({
          title: locationPermissionsHeader,
          variables: variables,
          message: locationPermissionsBody,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                this.setState({
                  loading: true,
                });
                this.getLocationPermission()
              }
            }
          ],
        })
      } else {
        this.setState({
          loading: true,
        });
        this.getLocationPermission()
      }
    }

    getCompletedSquares(bingoSquares){
      const arrayOfWinners = [];
      for (const i in bingoSquares) {
        const square = bingoSquares[i];
        if (square.completed) {
          const squareId = square.id || square.key;
          arrayOfWinners.push(squareId);
        }
      }
      return arrayOfWinners
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    winnerToggle() {
      this.setState({
        winnerModal: !this.state.winnerModal,
        startConfetti: false,
        downloadedFile: null
      });
    }

    toggleRules() {
      this.setState({
        modalRules: !this.state.modalRules,
      });
    }

    toggleSupport(){
      this.setState({
        modalSupport: !this.state.modalSupport
      });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
      this.props.appStorage.removeItem('userEmail');
      this.props.appStorage.removeItem('verifiedAge');
      this.props.appStorage.removeItem('birthday');
      this.props.appStorage.removeItem('locationPermissions');
      this.props.appStorage.removeItem('id_token');
      this.setState({
        userEmail:false
      })
    }

    fireWinningMessage(didWin){
      const variables = this.props.variables;
      const vm = this;
      if(didWin){
        this.setState({
          startConfetti: true
        })
      }
      let messageHeader = variables.winningHeader;
      let messageBody = variables.winningMessage;
      if (!messageHeader) {
        messageHeader = "You Win!";
      }
      if (!messageBody) {
        messageBody = "Congratulations";
      }
      if(!didWin) {
        messageHeader = variables.losingHeader || "Bingo but no prizes left!";
        messageBody = variables.losingMessage;
      }
      confirmAlert({
        variables: variables,
        title: messageHeader,
        message: messageBody,
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              vm.setState({startConfetti: false})
            }
          }
        ]
      })
    }

    renderHoldingScreen(){
      const tenantVariables = this.props.variables || {};
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const playingTextHeader = tenantVariables.playingTextHeader || "No Game Up";
      const playingTextBody = tenantVariables.playingTextBody || "Come back later to play";

      return(
          <>
              <div className="hero-text-container">
                <img src={frontLogoImage} className="main-hero-image" alt=""/>
              </div>
              <p style={{color:logOutButtonColor}}>
                <span className="emphasizedText">{playingTextHeader}</span>
                <br/>
                <span style={{color:logOutButtonColor}} dangerouslySetInnerHTML={{ __html:playingTextBody}}/>
              </p>
          </>
        )
    }

    calculateIfBingo(userSquaresArray, totalSquares, arrayOfCompletedSquares){
      const winningRows = [];
      const winningColumns = [];
      const winningDiagonals = [];
      const rowLength = Math.sqrt(totalSquares);
      let winningRow = [];
      let count = 0;
      let row = 0;
      userSquaresArray.sort((a, b) => a.location - b.location)
      for(const i in userSquaresArray){
        count++;
        const userSquare = userSquaresArray[i];
        let columnCount = count - 1;
        if(!winningColumns[columnCount%rowLength]){
          winningColumns[columnCount%rowLength] = [];
        }
        const squareId = userSquare.id || userSquare.key;
        winningColumns[columnCount%rowLength].push(squareId);
        const halfWayPointAvailable = totalSquares % 2 === 1;
        let halfWayPoint;
        if(halfWayPointAvailable && totalSquares > 8){
          halfWayPoint = Math.floor((totalSquares / 2));
        }
        if(halfWayPoint && halfWayPoint === parseInt(columnCount)){
          winningDiagonals[0].push(squareId);
          winningDiagonals[1].push(squareId);
        } else if(row === winningRow.length){
          if(!winningDiagonals[0]){
            winningDiagonals[0] = [];
          }
          winningDiagonals[0].push(squareId);
        } else if((row + winningRow.length) === (rowLength - 1)){
          if(!winningDiagonals[1]){
            winningDiagonals[1] = [];
          }
          winningDiagonals[1].push(squareId);
        }
        winningRow.push(squareId);
        if(winningRow.length >= rowLength){
          winningRows.push(winningRow);
          winningRow = [];
          row++;
        }
      }
      let isWinner = false;
      for(let t in winningRows){
        if(!isWinner){
          isWinner = this.checkForMatchingRows(winningRows[t], arrayOfCompletedSquares);
        }
      }
      if(!isWinner){
        for(let j in winningColumns){
          if(!isWinner){
            isWinner = this.checkForMatchingRows(winningColumns[j], arrayOfCompletedSquares);
          }
        }
      }
      if(!isWinner){
        for(const z in winningDiagonals){
          if(!isWinner){
            isWinner = this.checkForMatchingRows(winningDiagonals[z], arrayOfCompletedSquares);
          }
        }
      }
      return isWinner;
    }

    checkForCheckedBingo(item, itemId){
      const arrayOfScratchedSquares = this.state.arrayOfScratchedSquares || [];
      if(arrayOfScratchedSquares.indexOf(itemId) === -1){
        arrayOfScratchedSquares.push(itemId);
      }
      const userSquares = this.state.bingoSquares || [];
      const totalSquares = userSquares.length;
      userSquares.sort((a, b) => a.location - b.location)
      for(const i in userSquares){
        const userSquareId = userSquares[i].id || userSquares[i].key;
        if(userSquares[i].scratched && arrayOfScratchedSquares.indexOf(userSquareId) === -1){
          arrayOfScratchedSquares.push(userSquareId)
        }
      }
      const rowLength = Math.sqrt(totalSquares);
      if(arrayOfScratchedSquares.length < rowLength){
        return;
      }
      let isWinner = this.calculateIfBingo(userSquares, totalSquares, arrayOfScratchedSquares);
      if(isWinner){
        const variables = this.props.variables;
        confirmAlert({
          variables: variables,
          title: variables.checkedBingoHeader || "Verifying Stats",
          message: variables.checkedBingoMessage,
          buttons: [
            {
              label: 'OK'
            }
          ]
        })
      }
    }

    checkForMatchingRows(arr1, arr2){
      for(const i in arr1){
        const squareId = arr1[i];
        if(arr2.indexOf(squareId) === -1){
          return false
        }
      }
      return true;
    }

    async setSquareScratched(item, index){
      const userEmail = this.state.userEmail;
      let userId = btoa(userEmail);
      if(!validateEmail(userEmail)){
        userId = userEmail;
      }
      const itemId = item.id || item.key;
      this.checkForCheckedBingo(item, itemId);
      try {
        await appDatabasePrimaryFunctions.ref(`userAnswers/${userId}/${itemId}/scratched`).set(true);
        const bingoSquaresCopy = this.state.bingoSquares;
        bingoSquaresCopy[index]['scratched'] = true;
        await this.setState({
          bingoSquares: bingoSquaresCopy
        })
        const arrayOfScratchedSquares = this.state.arrayOfScratchedSquares || [];
        if(arrayOfScratchedSquares.indexOf(itemId) === -1){
          arrayOfScratchedSquares.push(itemId)
          this.setState({
            arrayOfScratchedSquares: arrayOfScratchedSquares
          })
        }
      } catch (e) {
        console.log("Error saving scratched: ", e)
      }
    }

    disablePullToRefresh(){
      let prevent = false;
      const element = '#root';
      document.querySelector(element).addEventListener('touchstart', function(e){
        if (e.touches.length !== 1) { return; }

        const scrollY = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
        prevent = (scrollY === 0);
      });

      document.querySelector(element).addEventListener('touchmove', function(e){
        if (prevent) {
          prevent = false;
          e.preventDefault();
        }
      });
    }

    disableDisablePullToRefresh(){
      let prevent = false;
      const element = '#root';
      document.querySelector(element).removeEventListener('touchstart', function(e){
        if (e.touches.length !== 1) { return; }

        const scrollY = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
        prevent = (scrollY === 0);
      });
      document.querySelector(element).removeEventListener('touchmove', function(e){
        if (prevent) {
          prevent = false;
          e.preventDefault();
        }
      });
    }

    handleTouchStart(e){
      document.body.classList.add("lock-background");
      this.disablePullToRefresh();
    }

    handleTouchEnd(e){
      document.body.classList.remove("lock-background");
      this.disableDisablePullToRefresh();
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const topLeftImage = tenantVariables.topHeaderImage || sampleAppTopImage;
      const filledInIcon = tenantVariables.filledInIcon || "";
      const bingoSquares = this.state.bingoSquares;
      const bingoSquaresOriginal = this.state.bingoSquaresObject || {};
      let sizeOfBingoSquares = 100;
      if(bingoSquares && bingoSquares.length > 0){
        const numberOfSquaresInRow = Math.sqrt(bingoSquares.length);
        const numberOfGutters = numberOfSquaresInRow - 1;
        const marginSpace = numberOfGutters * 5;
        sizeOfBingoSquares = (300 - marginSpace) / numberOfSquaresInRow;
      }
      return(
          <>
              <div className="creator-awards-logo-playscreen user">
                <img src={topLeftImage} alt="" style={{margin:0}}/>
              </div>
              <div className="spacer-md"/>
              {bingoSquares && bingoSquares.length > 0 &&
                <div style={{display:'flex', flexDirection:'row', flexWrap: "wrap", width:330, margin: "auto"}}>
                  {
                    bingoSquares.sort((a, b) => a.location - b.location).map(function(item, index){
                        if(!item.id){
                          return
                        }
                        const originalItem = bingoSquaresOriginal[item.id] || {};
                        const settings = {
                          width: sizeOfBingoSquares,
                          height: sizeOfBingoSquares,
                          image: originalItem.image,
                          finishPercent: 50,
                          brushSize:5,
                          onComplete: () => this.setSquareScratched(item,index)
                        };

                        return(
                            <div key={index} className="flip-container" style={{display:'flex', flexDirection: 'column', margin:5, width: sizeOfBingoSquares, height: sizeOfBingoSquares, flexGrow:1, alignItems: "center"}}>
                              <div style={{width: sizeOfBingoSquares, height: sizeOfBingoSquares}}>
                                {originalItem.completed || item.scratched ?
                                    <div className="background-image-styles" style={{backgroundImage: "url(" + originalItem.image + ")"}}>
                                      <img width={sizeOfBingoSquares} height={sizeOfBingoSquares} src={originalItem.checkedImage || filledInIcon || sampleCardImage} alt=""/>
                                    </div>
                                  :
                                    <div key={index} className="scratch-image" id="scratch-image" onTouchStart={(e)=>this.handleTouchStart(e)} onTouchEnd={(e)=>this.handleTouchEnd(e)}>
                                      <ScratchCard {...settings}>
                                        <div className="cover-image-container">
                                          <img width={sizeOfBingoSquares} height={sizeOfBingoSquares} src={originalItem.checkedImage || filledInIcon || sampleCardImage} className="cover-image-control" alt=""/>
                                        </div>
                                      </ScratchCard>
                                    </div>
                                }
                              </div>
                            </div>
                        )
                    }, this)
                  }
                </div>
              }
              <div style={{height:"100px", width:"1px"}}/>
          </>
        )
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.active && !this.state.active){
        this.removeReactAlert();
        this.restartClicked();
      }
    }

    restartClicked(){
      this.setState({
          bingoSquares: [],
          arrayOfScratchedSquares: [],
          startConfetti:false
      })
    }

    render() {
      const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
      let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
      let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
      const mlbPrivacyPolicyLink = tenantRules.mlbPrivacyPolicyLink || "https://www.mlb.com/app/ballpark/official-information/privacy-policy";
      let rulesPopUpText = tenantRules.rulesPopUpText;
      let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
      const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
      const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
      const howToPlayLink = tenantRules.howToPlayLink || "";
      let howToPlayText = tenantRules.howToPlayText || "";
      const link = tenantRules.rulesAndRegsLink;
      const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
      const rulesShowInApp = tenantRules.rulesShowInApp || (isMlbApp && !tenantVariables.noMandatoryTermsAndConditions) || false;
      if(rulesShowInApp) {
        if(!rulesShowInAppPopUpText && rulesPopUpText){
          rulesShowInAppPopUpText = rulesPopUpText;
        }
        if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
          rulesShowInAppPopUpHeader = rulesPopUpHeader;
        }
      }
      if(!this.state.userEmail){
        return (
            <Navigate to="/login"/>
        )
      } else if(!tenantVariables.doNotCollectEmail && !isMlbApp && !validateEmail(this.state.userEmail)){
        this.logout();
      } else if((tenantVariables.doNotCollectEmail || isMlbApp) && validateEmail(this.state.userEmail)){
        this.logout();
      }
      if(!verifiedAge){
        return (
            <Navigate to="/age_gate"/>
        )
      }
      if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading} backgroundImage={backgroundImage}/>
        )
      }
      let renderMainScreen = this.renderHoldingScreen()
      if(this.state.active === true && !this.state.ended && !this.state.locationChecking){
        renderMainScreen = this.renderPlayingScreen()
      }
      let showHowToPlayButton = false;
      if(howToPlayText || howToPlayLink){
        showHowToPlayButton = true
      }
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
            {this.state.startConfetti &&
              <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
            }
            <div className="flex-content-container-home">
              <div className="intro-container-home" style={{margin: "auto"}}>
                <div className="grid-wrapper">
                  <div className="flex-header-home" style={{marginTop:10}}>
                    {isMlbApp &&
                        <div style={{width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { this.toggleSupport() }}>
                            <Textfit mode="single" max={16}>
                              Support
                            </Textfit>
                          </button>
                        </div>
                    }
                    {isMlbApp &&
                        <div style={{ width: "25%", color: logOutButtonColor, borderColor: logOutButtonColor, height: 38, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => {window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank")}}>
                            <Textfit mode="single" max={16}>
                              MLB TOU
                            </Textfit>
                          </button>
                        </div>
                    }
                    {isMlbApp &&
                        <div style={{ width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { window.open(mlbPrivacyPolicyLink, "_blank") }}>
                            <Textfit mode="single" max={16}>
                              MLB Privacy Policy
                            </Textfit>
                          </button>
                        </div>
                    }
                    <div style={{display: showHowToPlayButton ?"": "none"}}>
                      <button onClick={()=>{howToPlayText? this.toggle():window.open(howToPlayLink, '_blank')}} className="btn btn-logout" style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>
                        <Textfit mode="single" max={16}>
                          How To Play
                        </Textfit>
                      </button>
                    </div>
                    <div style={{visibility: rulesShowInApp? "visible":"hidden"}}>
                      <button className="btn btn-logout" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>
                        <Textfit mode="single" max={16}>
                          {rulesInAppButtonText}
                        </Textfit>
                      </button>
                    </div>
                    <div style={{visibility: projectId === "seahawksbingogame" ? "hidden":"visible", display: (isMlbApp || this.props.passedEmail) && "none"}}>
                      <button className="btn btn-logout" onClick={() => { this.logout() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>
                        <Textfit mode="single" max={16}>
                          LOG OUT
                        </Textfit>
                      </button>
                    </div>
                  </div>
                  {renderMainScreen}
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                    {rulesShowInAppPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader/>
              <ModalBody style={{textAlign:"center"}}>
                <div className="container-out" style={{paddingLeft: 20, paddingRight: 20}}>
                  <span dangerouslySetInnerHTML={{ __html:howToPlayText}}/>
                  <button className="btn btn-play" onClick={() => { this.toggle(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>OK</button>
                </div>
              </ModalBody>
              <ModalFooter style={{borderTop:'none'}}/>
            </Modal>
            <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
              </ModalHeader>
              <ModalBody>
                <center className="container-out">
                  <div className="question-box question-form">
                    <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                    <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                  </div>
                </center>
              </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Main_App;
